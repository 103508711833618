/**
 * External Dependencies
 */

 import './components/nav';
 import './components/anim';
 import './components/modal';
 import './components/canvas';
 import './components/glide';
 import './components/weather';
 import LazyLoad from "vanilla-lazyload";
 
 //One offs
 // Lazyload images
 var myLazyLoad = new LazyLoad();

 