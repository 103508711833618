import $ from 'jquery';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

var $modal = $( '[data-modal]' );

if ( $modal.length ) {
  
  $( window ).on( 'hashchange', function() {
    updateModal( location.hash );
  });

  updateModal( location.hash );

  function updateModal( hash ) {
    if ( hash == '' ) {
      clearAllBodyScrollLocks();
    }

    if (  hash && hash.startsWith( '#!' )  ) {
      if (  hash !== '#!'  ) {
        disableBodyScroll(  document.getElementById(  hash.substr( 1 )  )  );
      } else {
        clearAllBodyScrollLocks();
      }
    }
  }
}

if( $( '.load-more' ).length ) {
  $( '.load-more' ).on( 'click', function (){
    $( '.card' ).removeClass( 'hidden' )
    $( '.load-more' ).hide()
  } )
}

