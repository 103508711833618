if( $( '.weatherapi' ).length ) {
  // Wrap fetch into function
  const fetchData = () => {
    fetch( 'https://api.weatherapi.com/v1/current.json?q=52.077762,-106.650844&key=f90fb63f3e46475b8d2212947222402' )

      .then( function( response ){
        // JSON that is returned from the server must be converted to a JS object asynchronously.
        if ( ! response.ok ) {
          throw new Error( 'Not 200 OK' );
        }
        return response.json();
      } )

      .then(  function( data ){
        const weatherDiv = document.querySelectorAll( '.weatherapi' )

        weatherDiv.forEach( ( elem, i ) => {
          weatherDiv[i].innerHTML = `
          <h2 class="headline-medium weather-title">Current Conditions</h2>
          <div class="temperature-container flex">
            <div class="conditions"><p>${ data.current.condition.text }</p></div>
            <div class="temperature"><p>${ data.current.temp_c }&deg;C</p></div>
          </div>
          `
        }  )
      } )

      .catch(  function( err ){
        // An error or `reject` from any of the above `.then()` blocks will end up here.
        console.log( err );
      } );
  }

  fetchData();
}